import React, { useState, useEffect } from "react"
import { Link, graphql, navigate } from "gatsby"
import styled from 'styled-components';
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Text from "../components/text"
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import { 
  NewsletterWrap, 
  margin,
  greyColor, 
  blackColor, 
  grid, 
  type_body, 
  type_body_bold,
  colorTransition 
} from '../styles/helpers'
import { 
  PageWrap, 
  PageWrapInner, 
  PageEntry,
  SinglePageWrap, 
  SinglePageWrapInner, 
  SinglePageClose, 
  PageEntryInner
} from '../styles/page'
import SubscribeFull from '../components/subscribeFull'


const PatreonText = styled.div`
  margin-bottom: 4rem;
  & h1 {
    ${type_body_bold};
    margin-bottom: 1em;
  }
  & p {
    ${type_body};
    & a {
      color: ${blackColor};
      transition: ${colorTransition};
      text-decoration: underline;
      &:hover {
        color: ${greyColor};
      }
    }
  }
`


const SubscribePage = ({ data, location, ...props }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 250)
}, [])

  return (
    <>
    <SEO title="Newsletter" />
    <PageWrap>
      <PageWrapInner columnWidth={8}>
        <PageEntry noPadding={true}>
            <PatreonText>
              <h1>Patreon</h1>
              <p>You can support the dormant office by subscribing to the platform’s Patreon page <a href="https://www.patreon.com/thedormantoffice" target="_blank">here</a>.</p>
            </PatreonText>
            <NewsletterWrap>
              <h1>Newsletter</h1>
              <SubscribeFull message="Subscribe to our newsletter to keep up with the dormant office's activities" />
            </NewsletterWrap>
        </PageEntry>
      </PageWrapInner>
    </PageWrap>
    </>
  )
}

export default SubscribePage
